<template>
	<div class="padding-top-10">
		<top :active='1'></top>
		<div class="home padding-lr-30 fs30">

		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			top,
			bottom
		},
		name: 'writer'
	}
</script>

<style>
</style>
